// extracted by mini-css-extract-plugin
export const title = "photos-module--title--2eBib";
export const p = "photos-module--p--3udn4";
export const story = "photos-module--story--26wST";
export const storybox = "photos-module--storybox--2lupX";
export const storyboxDiv = "photos-module--storybox-div--3Zzb2";
export const link = "photos-module--link--csQdg";
export const photosFullpage = "photos-module--photos--fullpage--2_t-l";
export const video = "photos-module--video--1bTI5";
export const photosPage = "photos-module--photos--page--FTkHw";
export const photosMapsize = "photos-module--photos-mapsize--3NVQK";
export const photosGalery = "photos-module--photos-galery--1UNJF";
export const photosTitle = "photos-module--photos-title--3cdad";
export const galeryPage = "photos-module--galery-page--3hUbC";
export const galeryPhotosize = "photos-module--galery-photosize--r7HsE";
export const galeryTitle = "photos-module--galery-title--YapvG";
export const photosImage = "photos-module--photos--image--2CjZD";